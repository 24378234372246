export const soon = {
  title: "Come Back Later...",
  body: `<br />
    <h1>Hi, you come to early</h1>
    <br /><br /><br />
    <p>
        I know this page is very interesting for you, especially for your special day but.<br />
        You need to be patience until the time has come, right ?
    </p>`,
};

export const late = {
  title: "See you next time...",
  body: `<br />
    <h1>See you next time... 🥰</h1>
    <br /><br /><br />
    <p>
        Yes, my gift for you is kinda simple, cheap, and weird ? &#128534<br>
        but. It's only for you. &#128150
    </p>
<h3> If you want to see it again, tell me</h3><br>
<a href="https://api.whatsapp.com/send?phone=+94754342061&text=hey" target="_blank">
  <button style="background-color: green; color: white; padding: 10px 16px; font-size: 16px; border: none; cursor: pointer;">Click here</button>
</a><br>

	<div class="container">
		<p id="browser-info"></p>

		<script>
			// Detect user's browser
			var browser = navigator.userAgent;

			// Display browser information on webpage
			document.getElementById("browser-info").innerHTML = "" + browser;
		</script>
	</div>
`,
};
